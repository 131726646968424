import {common} from '@/settings'
import {extend} from "lodash-es";
import axios from "axios";
import {getSearchParams} from "@/services/utils/searchBuilder";
import {routerError} from "@/services/utils/router";

const API_URL = common.api.url

const HYPERV_API_URL = common.api.site_specific_url + '/hypervisor'
const SERV_API_URL = common.api.site_specific_url + '/server'
const STORAGE_API_URL = common.api.url + '/storage'
const IP_API_URL = common.api.site_specific_url + '/ip'
const ADMIN_SERV_API_URL = common.api.site_specific_url + '/admin/server'
const CONSOLE_API_URL = common.api.site_specific_url + '/console'

const SUPPORTED_VM_ACTIONS = [
  'start', 'stop', 'hardstop', 'reboot', 'suspend', 'resume', 'reset'
]
// const SUPPORTED_IPKVM_ACTIONS = [
//   'start', 'stop', 'refresh'
// ]

// Waiting / Grey
const WAITING_STATES = [
  "unpaid",
  "suspended",
  "disabled",
  "unknow",
]

// Pending / Orange
const PENDING_STATES = [
  "suspending",
  "starting",
  "stopping",
  "resuming",
  "upgrading",
  "disabling",
  "enabling",
  "deleting",
  "reseting",
  "restoring",
  "rebooting",
  "creating",
  "updating"
]
// Started / Green
const STARTED_STATES = [
  "started",
  "resumed",
  "restored",
  "up"
]
// Stopped / Red
const STOPPED_STATES = [
  "stopped",
  "terminated"
]

// Stopped / Red
const DELETED_STATES = [
  "deleted"
]

class ServersService {
  list(company_oid) {
    return axios.get(SERV_API_URL + '?company_oid=' + encodeURIComponent(company_oid))
  }

  listWithFilters(searchParams) {
    return axios.get(SERV_API_URL + '' + (searchParams ? '?' + searchParams.toString() : ''))
  }

  get(server_oid) {
    return axios.get(SERV_API_URL + '/' + server_oid).catch(e => { routerError(e) })
  }

  // listTemplates() {
  //   return axios.get(STORAGE_API_URL + '/template')
  // }

  listImages(params) {
    return axios.get(STORAGE_API_URL + '/template?' + getSearchParams(params))
  }

  listOS() {
    return axios.get(STORAGE_API_URL + '/template/grouped')
  }
  listOSForReset(server_oid) {
    return axios.get(STORAGE_API_URL + '/template/reset?server_oid='+server_oid)
  }

  action(server_oid, action_payload) {
    if (!SUPPORTED_VM_ACTIONS.includes(action_payload.action)) {
      throw new Error('Unsupported action ' + action_payload.action)
    }
    return axios.put(SERV_API_URL + '/' + server_oid + '/state?action=' + action_payload.action)
  }

  // async snapshots(server_oid) {
  //   return (await axios.get(API_URL + '/' + server_oid + '/snapshots')).reverse()
  // }

  snapshotRename(snap_oid, name) {
    return axios.put(STORAGE_API_URL + '/snapshot/' + snap_oid, {name})
  }

  snapshotRestore(snap_oid) {
    return axios.put(STORAGE_API_URL + '/snapshot/' + snap_oid + '/restore')
  }

  snapshotCreate(server_oid, name = '') {
    return axios.post(STORAGE_API_URL + '/' + server_oid + '/snapshot', {name})
  }

  snapshotDelete(server_oid, snap_oid) {
    return axios.delete(STORAGE_API_URL + '/snapshot/' + snap_oid)
  }

  // ISO Management
  listISOs() {
    return axios.get(STORAGE_API_URL + '/iso');
  }

  removeISO(server_oid, iso_oid) {
    return axios.delete(STORAGE_API_URL + '/' + server_oid + '/iso/' + iso_oid)
  }

  attachISO(iso, server_oid) {
    return axios.post(STORAGE_API_URL + '/' + server_oid + '/iso', {
      protocol: 'https',
      iso_addr: iso
    })
  }

  update(server_oid, payload) {
    return axios.put(SERV_API_URL + '/' + server_oid, payload)
  }

  updateIP(ip, data) {
    return axios.put(IP_API_URL + '/' + ip, data)
  }

  updateOutsourcing(payload) {
    return axios.put(SERV_API_URL + '/update_outsourcing', payload)
  }

  gauges(server_oid) {
    return axios.get(common.api.site_specific_url + '/usage/' + server_oid)
  }

  gaugesTest(server_oid) {
    return axios.get("http://localhost:8081/v2" + '/usage/' + server_oid)
  }

  gaugesPinnedServer(user_oid) {
    return axios.get(common.api.site_specific_url + '/usage/pinned/'+user_oid)
  }

  gaugesPinnedServerTest(user_oid) {
    return axios.get("http://localhost:8081/v2" + '/usage/pinned/'+user_oid)
  }

  startIPKVM(server_oid, ttl_minutes = 60) {
    return axios.post(CONSOLE_API_URL + '/kvmip', {
      server_oid: server_oid,
      deadline: ttl_minutes * 60
    })
  }

  uptadeIPKVM(oid, server_oid) {
    return axios.put(CONSOLE_API_URL + '/kvmip/' + oid, {
      server_oid: server_oid,
      deadline: 60 * 60
    })
  }

  stopIPKVM(oid) {
    return axios.delete(CONSOLE_API_URL + '/kvmip/' + oid)
  }

  async dashboard() {
    //return await axios.get(common.api.url + '/dashboard')
    return await axios.get('http://localhost:8082/v1/dashboard')
  }

  // async transfer(server_oid, data) {
  //   return await axios.put(COMPUTE_API_URL + SERV_API_URL + '/' + server_oid + '/transfer', data)
  // }

  getAddonInfo(server_oid) {
    return axios.get(SERV_API_URL + '/' + server_oid + '/addon/info')
  }

  upgrade(server_oid, addons, paymentMethodID, credits, promoCode) {
    return axios.post(SERV_API_URL + '/' + server_oid + '/addons', {
      addons: addons,
      payment_method_id: paymentMethodID,
      credits: credits,
      promotion_code: promoCode
    })
  }

  downgrade(server_oid, addons) {
    return axios.delete(SERV_API_URL + '/' + server_oid + '/addons', {
      data: {
        items: addons
      }
    });
  }

  async detail(server_oid) {
    const [server_detail, events] = await Promise.all([
      this.get(server_oid),
      this.events(server_oid),
    ]);
    return extend(server_detail, {events})
  }

  events(server_oid, payload) {
    return axios.get(SERV_API_URL + '/' + server_oid.toString() + '/events',{params: payload})
  }

  eventsCsv(server_oid, payload) {
    return axios.get(SERV_API_URL + '/' + server_oid.toString() + '/events/csv',{params: payload})
  }

  histo(server_oid, {metric, start, end}) {
    return axios.get(common.api.site_specific_url + '/history/' + server_oid +
      '/?'
      + 'metric=' + metric
      + '&start=' + (start || (Date.now() - (11 * 60 * 60 * 1000)))
      + '&end=' + (end || Date.now())
    )
  }

  newHisto(server_oid, {metric, start, end}) {
    return axios.get(common.api.site_specific_url + '/history/' + server_oid +
      '/new/?'
      + 'metric=' + metric
      + '&start=' + (start || (Date.now() - (11 * 60 * 60 * 1000)))
      + '&end=' + (end || Date.now())
    )
  }

  newHistoTest(server_oid, {metric, start, end}) {
    return axios.get("http://localhost:8081/v2" + '/history/' + server_oid +
      '/new/?'
      + 'metric=' + metric
      + '&start=' + (start || (Date.now() - (11 * 60 * 60 * 1000)))
      + '&end=' + (end || Date.now())
    )
  }

  uptime(server_oid, {metric, start, end}) {
    return axios.get(common.api.site_specific_url + '/uptime/' + server_oid +
      '/?'
      + 'metric=' + metric
      + '&start=' + (start || (Date.now() - (24 * 60 * 60 * 1000)))
      + '&end=' + (end || Date.now())
    )
  }

  uptimeDays(server_oid, numberOfDays) {
    return axios.get(common.api.site_specific_url + '/uptime/' + server_oid +
      '/average/?'
      + 'number_of_days=' + numberOfDays
    )
  }

  uptimeTest(server_oid, {metric, start, end}) {
    return axios.get("http://localhost:8081/v2" + '/uptime/' + server_oid +
      '/?'
      + 'metric=' + metric
      + '&start=' + (start || (Date.now() - (24 * 60 * 60 * 1000)))
      + '&end=' + (end || Date.now())
    )
  }

  uptimeDaysTest(server_oid, numberOfDays) {
    return axios.get("http://localhost:8081/v2" + '/uptime/' + server_oid +
      '/average/?'
      + 'number_of_days=' + numberOfDays
    )
  }

  mirroringState(server_oid) {
    return axios.get(common.api.site_specific_url + '/mirroring/' + server_oid)
  }

  mirroringStateTest(server_oid) {
    return axios.get("http://localhost:8081/v2" + '/mirroring/' + server_oid)
  }

  getActionsFromState(state) {
    if (['starting', 'started', 'resumed', 'resuming'].includes(state)) {
      return [
        'stop',
        'reboot',
        'hardstop',
        'reset',
        'terminate',
        'cancel-terminate',
        'schedule-reboot'
      ]
    }
    if (['stopping', 'rebooting'].includes(state)) {
      return [
        'hardstop',
        'reset',
        'terminate',
        'cancel-terminate'
      ]
    }
    if (['stopped'].includes(state)) {
      return [
        'start',
        'reset',
        'terminate',
        'cancel-terminate'
      ]
    }
    if (['suspending'].includes(state)) {
      return [
        'resume',
        'hardstop',
        'reset',
        'terminate',
        'cancel-terminate'
      ]
    }
    if (['suspended'].includes(state)) {
      return [
        'resume',
        'reset',
        'terminate',
        'cancel-terminate'
      ]
    }
    if (['reseting', 'deleting', 'deleted', 'unpaid'].includes(state)) {
      return []
    }
    return [
      'terminate',
      'cancel-terminate'
    ]
  }

  getGlobalStateFromState(state) {
    // Waiting / Grey
    if (WAITING_STATES.includes(state)) {
      return 'waiting'
    }
    // Pending / Orange
    if (PENDING_STATES.includes(state)) {
      return 'pending'
    }
    // Started / Green
    if (STARTED_STATES.includes(state)) {
      return 'started'
    }
    // Stopped / Red
    if (STOPPED_STATES.includes(state)) {
      return 'stopped'
    }
    // Stopped / Red
    if (DELETED_STATES.includes(state)) {
      return 'waiting'
    }

    // Other
    return state
  }

  getAllStatesMatchingGlobalState(state) {
    // Waiting / Grey
    if (state === 'waiting') {
      return WAITING_STATES
    }
    // Pending / Orange
    if (state === 'pending') {
      return PENDING_STATES
    }
    // Started / Green
    if (state === 'started') {
      return STARTED_STATES
    }
    // Stopped / Red
    if (state === 'stopped') {
      return STOPPED_STATES
    }

    if (state === 'deleted') {
      return DELETED_STATES
    }

    // Other
    return [state]
  }

  delete(server_oid, reason) {
    return axios.delete(SERV_API_URL + '/' + server_oid, {
      data: {
        reason: reason
      }
    });
  }

  cancelDeletion(server_oid) {
    return axios.patch(SERV_API_URL + '/' + server_oid)
  }

  reset(server_oid, action_payload) {
    return axios.post(SERV_API_URL + '/' + server_oid + '/reset', action_payload)
  }

  async ipkvmInfos(server_oid) {
    return await axios.get(API_URL + '/' + server_oid + '/ipkvm')
  }

  async addIPv6(server_oid) {
    return await axios.post(IP_API_URL + '/' + server_oid + '/enable_ipv6')
  }

  async serverEnableDemo(server_oid, payload) {
    return await axios.put(SERV_API_URL + '/' + server_oid + '/enable_demo', payload)
  }

  async serverDisableDemo(server_oid, payload) {
    return await axios.put(SERV_API_URL + '/' + server_oid + '/disable_demo', payload)
  }

  async serverEnableServer(server_oid) {
    return await axios.put(SERV_API_URL + '/' + server_oid + '/enable')
  }

  async serverDisableServer(server_oid) {
    return await axios.put(SERV_API_URL + '/' + server_oid + '/disable')
  }

  // async historyIP(ip) {
  //   return await axios.get(COMPUTE_API_URL + '/ip/' + ip + '/history')
  // }

  async listHypervisor() {
    return axios.get(HYPERV_API_URL)
  }

  async hypervisorChangeState(oid, state) {
    return axios.post(HYPERV_API_URL + '/' + oid + '/' + state)
  }

  async changeAllHypervisorState(listOfChanges) {
    return axios.post(HYPERV_API_URL + '/all', {list_of_changes: listOfChanges})
  }

  // async hypervisorMigrate(mode, HypervSrc, HypervDst, server_oid) {
  //   return axios.put(COMPUTE_API_URL + '/migrate', {
  //     "mode": mode,
  //     "oid": server_oid,
  //     "hyperv_src": HypervSrc ? HypervSrc.oid : '',
  //     "hyperv_dst": HypervDst ? HypervDst.oid : ''
  //   })
  // }

  async createImageTemplateFromSnap(name, server_oid, snap_oid) {
    return axios.post(STORAGE_API_URL + '/image', {
      "name": name,
      "server_oid": server_oid,
      "snapshot_oid": snap_oid
    })
  }

  rebootInfo(server_oid) {
    return axios.get(SERV_API_URL + '/' + server_oid.toString() + '/reboot/info')
  }

  deleteServer(server_oid, payload) {
    return axios.delete(ADMIN_SERV_API_URL + '/' + server_oid.toString() + "/admin", {data: payload})
  }

  getIpRedirection(payload) {
    return axios.get(IP_API_URL + '/redirection',{params: payload})
  }
  
  deleteProvisioningServers(payload) {
    return axios.delete(ADMIN_SERV_API_URL + "/provisioning", {data: payload})
  }

  deleteOneProvisioningServer(server_oid) {
    return axios.delete(ADMIN_SERV_API_URL + '/' + server_oid.toString() + "/provisioning")
  }

  getDiskUsage(payload) {
    return axios.get(SERV_API_URL + '/disk_usage',{params: payload})
  }

  updateOs(server_oid, payload) {
    return axios.put(SERV_API_URL + '/' + server_oid + '/os', payload)
  }

  updateHypervisor(server_oid, payload) {
    return axios.put(SERV_API_URL + '/' + server_oid + '/hyperv', payload)
  }

  getManagedServicePdf(server_oid) {
    return axios.get(SERV_API_URL + '/' + server_oid + '/ms_pdf', {responseType: 'blob'})
  }

  deactivateDrp(server_oid) {
    return axios.delete(SERV_API_URL + '/' + server_oid + '/drp')
  }
}

export default new ServersService()
