import {i18n} from "@/i18n";

const {t} = i18n.global;
const {te} = i18n.global;

function transformToString(dataArray) {
  return dataArray
    .map(obj => `${translate(obj.field, obj.value)}`) // Utilise la fonction te pour traduire le field
    .join(', '); // Joint toutes les chaînes séparées par ", "
}

function translate(str1, str2) {
  if (te('fields_error.' + str1 + '_' + str2)) return t('fields_error.' + str1 + '_' + str2)
  if (str1 === 'API') {
    return t('errors.' + str2)
  }
  return str1 + ' ' + str2
}

export function translateError(error) {
  if (error.response) {
    if (error.response.data) {
      if (error.response.data.message) {
        return t('errors.' + error.response.data.message);
      } else {
        if (te(error.response.data.error)) {
          return t(error.response.data.error) + ' ' + transformToString(error.response.data.data);
        }
        return error.response.data.error + ' ' + transformToString(error.response.data.data);
      }
    } else {
      return error.message;
    }
  } else {
    return "Error";
  }
}

export function translateSuccess(response) {
  return response.code ? t('errors.' + response.code) : response.success
}

/**
 * Try catch with translation
 * @param func
 * @param withMsgSuccess
 * @param onErrorValue
 * @return {Promise<{response: Object|Array<Object>|null,errorData: Object|Array<Object>|null, successMsg: string|null, totalItems: Number, errorMsg: string|null, isSuccess: boolean, isError: boolean}>}
 */
export async function tryCatch(func, withMsgSuccess = false, onErrorValue = null) {
  let object = {
    successMsg: null,
    errorMsg: null,
    errorData: null,
    response: onErrorValue,
    totalItems: 0,
    isSuccess: false,
    isError: false,
  }

  try {
    let result = (await func())

    if (result === undefined) {
      object.isError = true
      return object
    }
    object.response = result.data
    object.isSuccess = result.status < 300 && result.status >= 200
    object.isError = result.status >= 400
    object.totalItems = result.totalItems

    if (withMsgSuccess)
      object.successMsg = translateSuccess(object.response)
    if (result.isSuccess && !withMsgSuccess)
      object.successMsg = t("errors." + result.status);
    return object
  } catch (error) {
    object.isError = true
    object.errorMsg = translateError(error)

    if (error.response && error.response.data)
      object.errorData = error.response.data

    // console.log(object)

    // createToast(
    //   object.errorMsg, {
    //     type: 'danger',
    //     position: 'top-right',
    //     showIcon: true,
    //     timeout: 5000
    //   }
    // );

    return object
  }
}


