import {common} from '@/settings';
import axios from "axios";
import {tryCatch} from "@/components/shared/util";
import userService from "./user.service";
import {Base64} from "js-base64";
import {toLower} from "lodash-es";
import {i18n} from "@/i18n";

const API_URL = common.api.url;
const API_URL_AUTH = API_URL + '/auth';
// const API_URL_PREFERENCE = API_URL + '/user/preference';

class AuthService {
  CurrentUser;
  
  parsingJwt(token) {
    if (!token) {
      return false;
    }
    var base64Url = token.split('.')[1];
    return JSON.parse(Base64.decode(base64Url));
  }

  email_validator(email) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
  }

  setAuthenticated(user) {
    if (!user || !user.preference)
      return
    this.CurrentUser = user;
    localStorage.setItem('authenticated', JSON.stringify(user));
    localStorage.setItem('preference', JSON.stringify(user.preference));
  }

  getAuthenticated() {
    if (localStorage.getItem('authenticated') === "")
      return localStorage.clear()
    return this.getToken() && JSON.parse(localStorage.getItem('authenticated'));
  }

  setLogged(state) {
    localStorage.setItem('logged', JSON.stringify(state));
  }

  getLogged() {
    return JSON.parse(localStorage.getItem("logged"));
  }

  setToken({token, expire}) {
    localStorage.setItem('token', token);
    localStorage.setItem('token_expire', expire);
    // console.log(token)
    // console.log(expire)
    const expire_when = new Date(expire);
    const refresh_in = expire_when - Date.now() - 30 * 60 * 1000;

    setTimeout(this.refreshToken.bind(this), refresh_in);
  }

  getToken() {
    //Add 1h to expire date to correspond to refresh_token
    if (!localStorage.getItem('token_expire'))
      return ""

    const expire = Date.parse(localStorage.getItem('token_expire')) - Date.now()
    // console.log(expire)
    if (expire < -(30 * 60 * 60 * 1000)) {
      return this.logout()
    }

    return localStorage.getItem('token');
  }

  get2FA() {
    return axios.post(API_URL + '/2fa')
  }

  activate2FA(code) {
    return axios.put(API_URL + '/2fa', {code})
  }

  deactivate2FA(password, code) {
    return axios.delete(API_URL + '/2fa', {data: {password, code}})
  }

  deactivate2FAByAdmin(userOID) {
    return axios.delete(API_URL + '/admin/2fa', {data: {user_to_deactivate: userOID}})
  }

  getCurrentCGV() {
    return axios.get(API_URL + '/cgv/current')
  }

  async clearSession(usePrefs) {
    console.error("CLEARSESSION")
    userService.updateUserPreferences(usePrefs).then(() => {
        this.clearLocalStorage()
        localStorage.clear()
      }
    ).catch(() => {
      localStorage.clear()
    })
  }

  clearLocalStorage() {
    window.localStorage.clear()
  }

  getAuthHeader() {
    let token = this.getToken();
    if (token) {
      return {'Authorization': 'Bearer ' + token};
    } else {
      return {};
    }
  }

  getAuthQuery(separator = "&") {
    let token = this.getToken();
    if (token) {
      return separator + "api_key=" + token;
    } else {
      return "";
    }
  }


  /**
   * Login function
   *
   * @param email
   * @param password
   * @param code
   * @param redirect
   * @returns {Promise<{error: String | StringConstructor, user: Object|null}>}
   */
  async login({email = null, password = null, code = null, redirect}) {
    const finalResul = {
      user: Object | null,
      error: String,
    };

    let result = await tryCatch(() => axios.post(API_URL_AUTH + '/login', {
      email: email,
      password: password,
      two_fa_code: code
    }));
    finalResul.error = result.errorMsg

    console.log(redirect)
    if (result.isSuccess && result.response.token) {
      this.setToken({token: result.response.token, expire: result.response.expire})

      const jwt_payload = this.parsingJwt(result.response.token)

      // console.log(jwt_payload)

      if (!jwt_payload.authorized && jwt_payload['2fa']) {

        finalResul.error = "2fa_needed"
        return finalResul
      } else if (jwt_payload.authorized) {
        // console.log(result.response.token)
        let userResult = await tryCatch(() => userService.myUser())
        finalResul.error = userResult.errorMsg
        // console.log(userResult)
        if (userResult.isSuccess) {
          if(!userResult.response.registration.confirmed) {
            finalResul.error = i18n.global.t("errors.ACCOUNT_NOT_VALIDATED");
          } else {
            this.setAuthenticated(userResult.response)
            this.setLogged(true)
            finalResul.user = userResult.response
          }
        }
      }
    }

    return finalResul
  }

  async loginFromToken(token = null) {
    const finalResul = {
      user: Object | null,
      error: String,
    };

    if (token) {
      this.setToken({token: token, expire: Date.now() + 60 * 60 * 1000})

      const jwt_payload = this.parsingJwt(token)

      // console.log(jwt_payload)

      if (!jwt_payload.authorized && jwt_payload['2fa']) {

        finalResul.error = "2fa_needed"
        return finalResul
      } else if (jwt_payload.authorized) {
        // console.log(result.response.token)
        let userResult = await tryCatch(() => userService.myUser())
        finalResul.error = userResult.errorMsg
        // console.log(userResult)
        if (userResult.isSuccess) {
          if(!userResult.response.registration.confirmed) {
            finalResul.error = i18n.global.t("errors.ACCOUNT_NOT_VALIDATED");
          } else {
            this.setAuthenticated(userResult.response)
            this.setLogged(true)
            finalResul.user = userResult.response
          }
        }
      }
    }

    return finalResul
  }

  async getDashboard() {
    return await axios.get(API_URL_AUTH + '/user/dashboard')
  }

  async refreshToken() {
    let result = await tryCatch(() => axios.get(API_URL_AUTH + '/refresh'))
    if (result.isSuccess && result.response.token) {
      this.setToken(result.response)
    }
    return result.response
  }


  async resetPassword(payload) {
    // console.log('resetPassword', payload)
    return await axios.put(API_URL + '/user/new_password', payload)
  }
  

  logout() {
    window.location.replace('/login')
  }

  need2FACode() {
    const user = this.getAuthenticated()
    const token = localStorage.getItem('token')
    if (!token || !user) return
    const jwt_payload = this.parsingJwt(token)
    // console.log('need2FACode', user, user && user.twofa, jwt_payload, jwt_payload && !jwt_payload.authorized)
    return user && user.twofa && !jwt_payload.authorized
  }


  async auth2FA(code) {
    const jwt = await axios.put(API_URL_AUTH + '/2fa', {code})
    if (jwt.token) {
      this.setToken(jwt)
      // console.log(parseJwt(jwt.token))
      let result = await tryCatch(() => userService.myUser());
      const user = result.response;
      // if (every(userInfos.companies, company => company.role.accountant)) {
      //   throw new Error('Accountant cannot login')
      // }
      this.setAuthenticated(user)
      this.setLogged(true)
      return user
    }
    return jwt
  }


  async signCGV(oid) {
    let result = await axios.post(API_URL + '/cgv/' + oid + "/sign")
    console.log("sign cgv", result)
    if (result.success) {
      let result = await tryCatch(() => userService.myUser());
      console.log(result)
      const user = result.response;
      this.setAuthenticated(user)
    }
    return result
  }

  async refreshUser() {
    let r = await tryCatch(() => userService.myUser());
    // console.log("get user")
    // console.log(r)
    let user = r.response;
    user = this.loadCompanies(user);
    this.setAuthenticated(user);
    user = this.setDrp(user);
    return user
  }

  async loadCompanies(user) {
    let result = await tryCatch(() => userService.myCompanies());
    user.companies_full = result.response;
    user = this.setRoles(user);
    return user;
  }
  
  setRoles(user) {
    if (user.companies_full && user.companies_full.length > 0) {
        user.companies.forEach((c) => {
            user.companies_full.forEach((c_full) => {
                if (c.oid === c_full.oid) {
                    c_full.role = c.role;
                }
            });
        });
    }
    return user;
  }
    
  setDrp(user) {
    user.drp_url = "";
    if (user.companies_full && user.companies_full.length > 0) {
      user.companies_full.forEach((c_full) => {
        if (user.company_oid === c_full.oid && c_full.drp_url) {
          user.drp_url = c_full.drp_url;
        }
      });
    }
    return user;
  }

  backgroundMode(user) {
    if (user && user.preference)
      return toLower(user.preference.color_mode);
    return "light";
  }
}

export default new AuthService()
